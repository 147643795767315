.footerMain {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: transparent;
    padding: 40px 120px;
    max-width: 1440px;
    margin: 0 auto;
    gap: 100px;
    flex-wrap: wrap;
}

.footerLogo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    gap: 24px;
}

.footerLogo img {
    width: 50px;
}

.footerLogo p {
    color: #080808;
    width: 310px;
}
li {
    list-style-type: none;
}

.footerList {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    gap: 12px;
}



@media screen and (max-width: 550px) {
    .footerMain {
        gap: 30px;
        padding: 20px 16px;
        justify-content: flex-start ;
    }
}
