.contactForm {
    display: flex;
    flex-direction: column;
    gap: 32px;
    width: 600px;
    margin: 0 auto;
    padding: 40px 20px;
    background-color: #EFEFEF;
    justify-content: center;
    align-items: center;
}

form {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin: 0 auto;
    justify-content: center;
    align-items: center
}

.inputs {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 460px;
}

.inputs input {
    background: transparent;
    border: 1px solid #b8b6b6;
    border-radius: 0px;
    width: 460px;
    height: 30px;
    padding: 0px 10px;
    box-shadow: none;
}

.inputs textarea {
    background: transparent;
    border: 1px solid #b8b6b6;
    border-radius: 0px;
    width: 460px;
    height: 100px;
    padding: 0px 10px;
    box-shadow: none;
}

.inputs label {
    color: #080808;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
}

form button {
    background-color: #079347;
    color: #fff;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    width: 460px;
    height: 40px;
    border: none;
}
form button:hover {
    cursor: pointer;
    background-color: #fff;
    color: #079347;
    border: 1px solid #079347;
}


@media screen and (max-width: 550px) {
    .contactContent {
        width: 100%;
    }
    .contactForm {
        width: 100%;
        padding: 20px 16px;
    }

    form {
        width: 100%;
    }

    .inputs {
        width: 100%;
    }

    .inputs input {
        width: 100%;
    }

    .inputs textarea {
        width: 100%;
    }

    form button {
        width: 100%;
    }
}