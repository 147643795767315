.ministersMain {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 40px;

}

.leadMinisters {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px;
}

.otherMinisters {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px;
}

.minister {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}
.minister img {
    width: 250px;
    height: 250px;
    object-fit: cover;
}

.ministerText {
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;
}


@media screen and (max-width: 550px) {
    .otherMinisters, .leadMinisters {
        flex-direction: column;
        gap: 16px;
    }

    .minister img {
        width: 300px;
        height: 300px;
    }
    
    
}