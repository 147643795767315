body {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  font-family: 'League Spartan';
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 100%;
}
h2 {
  font-family: 'League Spartan';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 100%;
}
h3 {
  font-family: 'League Spartan';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 100%;
}
h4 {
  font-family: 'League Spartan';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 100%;
}

p {
  font-family: 'Public Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;}




  @media screen and (max-width: 550px) {
    h1 {
      font-size: 24px;
    }
    h2 {
      font-size: 18px;
    }
    h3 {
      font-size: 16px;
    }
    h4 {
      font-size: 14px;
    }
    p {
      font-size: 14px;
    }
  }