* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.timing {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 40px 20px;
  background-color: none;
  margin: 0 auto;
}

.ministers {
  background-image: url('./resources/ministers\ BG.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-color: rgba(0, 0, 0, 0.564);
  background-blend-mode: overlay;
  height: 1000px;
  width: 100%;
  color: aliceblue;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 60px 20px;
  text-align: center;

}

.ministers p {
  max-width: 800px;
  margin-bottom: 24px;
}

.join {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 20px;
  background-color: none;
  margin: 0 auto;
  height: 500px;
  width: 100%;
}

.sponsors {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 20px;
  background-color: none;
  margin: 0 auto;
  height: 580px;
  width: 100%;

  background-image: url('./resources/Sponsors\ bg.png');
}

.contact {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 20px;
  background-color: none;
  margin: 0 auto;
  width: 100%;
  gap: 32px;
}

.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: none;
  border: 1px solid #EFEFEF;
  margin: 0 auto;
  width: 100%;
  gap: 32px;
}
.copyright {
  text-align: center;
  font-size: 14px;
  margin-bottom: 16px;
}

.video {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: none;
  margin: 0 auto;
  padding-bottom: 40px;
  width: 100%;
  gap: 32px;
}





@media screen and (max-width: 550px) {
  .ministers {
    height: auto;
    gap: 24px;
  }

  .timing {
    height: auto;
    gap: 16px;
    padding: 20px 20px;
  }

  .sponsors {
    height: auto;
    gap: 16px;
    padding: 20px 16px;
  }
  .contact {
    height: auto;
    gap: 16px;
    padding: 20px 16px;
  }
  
}