.joinMain {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px 20px;
    background-color: none;
    margin: 0 auto;
    gap: 24px;
    width: 900px;
}

.joinContent {
    display: flex;
    flex-direction: column;
    gap: 24px;
}
.joinContent button {
    background-color: #079347;
    color: #fff;
    padding: 10px 20px;
    border: none;
    font-weight: 600;
    width: 160px;
    height: 50px;
}
.joinContent button:hover {
    background-color: #fff;
    color: #079347;
    cursor: pointer;
    border: 1px solid #079347;
}
.joinContent p {
    color: #454545;
}


@media screen and (max-width: 550px) {
    .joinMain {
        flex-direction: column;
        gap: 24px;
        padding: 20px 16px;
        width: 100%;
    }

    .joinContent {
        text-align: center;
        gap: 16px;
        width: 100%;
        justify-content: center;
        align-items: center;
    }

    .joinContent button {
        width: 100px;
    }
}