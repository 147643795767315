.navMain {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px 20px;
    background-color: none;
    max-width: 1440px;
    margin: 0 auto;
}
.navMenu {
    display: flex;
    gap: 40px;
    color: #fff;
}
.navCTA {
    background-color: #079347;
    color: #fff;
    padding: 10px 20px;
    border: none;
    font-weight: 600;
    width: 112px;
    height: 36px;
} 
.navCTA:hover {
    background-color: #fff;
    color: #079347;
    cursor: pointer;
    border: 1px solid #079347;
}

.navMenuCTA {
    background-color: #079347;
    color: #fff;
    padding: 10px 20px;
    border: none;
    font-weight: 600;
    width: 112px;
    height: 36px;
}
.navLink {
    text-decoration: none;
    color: inherit;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  .navLink:hover {
    color: #079347;
  }
 
/*.navMain {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem 6rem;
    max-width: 1500px;
    width: 100%;
    align-self: center;
} */

.navContent {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.navContent img {
    width: 40px;
    height: 40px;
}

.navContentLinks {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 565px;
    color: white;
}

.navMenu {
    display: none;
    margin-left: 1rem;
    position: relative;
}

.navMenuContainer {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;

    text-align: end;
    background: #0B3249;
    padding: 2rem;
    position: absolute;
    top: 40px;
    right: 0;
    margin-top: 0;
    min-width: 210px;
    border-radius: 5px;
    box-shadow: 0 0 5 rgba(0, 0, 0, 0.2);
}
.navMenuContainer p {
    color: #fff;
    margin: 1rem 0;
}

.navMenuContainerLinks {
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: flex-start;
    align-items: flex-end;
    width: 100%;
}



@media screen and (max-width: 550px) {
    .navMain {
        padding: 1rem;
    }   
    .navContentLinks {
        display: none;
    }
    .navMenu {
        display: flex;
    }
    .navCTA {
        display: none;

    }
   
}