.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modalContainer {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    position: relative;
  }

  .modalContainer .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
    width: 24px;
    height: 24px;
    color: black;
  }
  .modalContainer .close-button:hover {
    border: none;
  }

  .paypal {

  }
  .paypal img {
    width: 150px;
  }
  .paypalContent {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
    margin-bottom: 16px;
  }
  .btn {
    width: 70px;
    border: none;
    background-color: #079347;
    color: white;
  }
  .btn:hover {
    border: solid 1px;
    cursor: pointer;
  }

  .paypalText p {
    font-size: 16px;
  }

  @media screen and (max-width: 550px) {
    .modalContainer {
        width: 100%;
    }
  }